import { noop } from 'underscore';
import { v as bvVar } from 'bv';
import doFetchSeoEntity from './api/fetch_seo_entity';

const FETCH_SEO_ENTITY_INIT = 'seo/FETCH_SEO_ENTITY_INIT';
const FETCH_SEO_ENTITY_SUCCESS = 'seo/FETCH_SEO_ENTITY_SUCCESS';
const UPDATE_SEO_ENTITY = 'seo/UPDATE_SEO_ENTITY';
const SET_META_INFO = 'seo/SET_META_INFO';
const SET_PRERENDER_READY = 'seo/SET_PRERENDER_READY';

const fetchSeoEntityInit = (path) => ({
  type: FETCH_SEO_ENTITY_INIT,
  path,
});

export const fetchSeoEntitySuccess = (response) => ({
  type: FETCH_SEO_ENTITY_SUCCESS,
  response,
});

export const updateSeoEntity = (delta) => ({
  type: UPDATE_SEO_ENTITY,
  delta,
});

const pathIsGame = (path) => /\/casino\/games\//.test(path);

export const fetchSeoEntity = (path) => (dispatch, getState) => {
  dispatch(fetchSeoEntityInit(path));

  if (pathIsGame(path)) return;
  doFetchSeoEntity(path)
    .then((response) => {
      // As we do not have cancelable requests
      // Ignore not anymore valid data, consistency
      if (getState().routing.currentPath === path) {
        dispatch(fetchSeoEntitySuccess(response));
      }
    })
    .catch(noop);
};

export const setMetaInfo = (metaInfo) => ({
  type: SET_META_INFO,
  metaInfo,
});

export const setPrerenderReady = () => ({
  type: SET_PRERENDER_READY,
});

const initialState = {
  heading: null,
  copySections: [],
  fetching: true,
  unavailableAfter: null,
  prerenderReady: false,
  hasToc: false,
};

const staticData = {
  brand: bvVar('siteName'),
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SEO_ENTITY_INIT:
      return {
        ...state,
        ...initialState,
        path: action.path,
      };
    case FETCH_SEO_ENTITY_SUCCESS: {
      const {
        copy_sections: copySections,
        custom_path: customPath,
        description,
        heading,
        title,
        default: isDefault,
        noindex,
        nofollow,
        has_custom_schema: hasCustomSchema,
        schema_json: schemaJson,
        has_toc: hasToc,
      } = action.response;

      return {
        ...state,
        fetching: false,
        copySections,
        description,
        heading,
        title,
        isDefault,
        customPath,
        noindex,
        nofollow,
        hasCustomSchema,
        schemaJson,
        hasToc,
      };
    }

    case UPDATE_SEO_ENTITY: {
      return {
        ...state,
        ...action.delta,
      };
    }

    case SET_META_INFO:
      return {
        ...state,
        metaInfo: {
          ...action.metaInfo,
          data: {
            ...action.metaInfo.data,
            ...staticData,
          },
        },
      };

    case SET_PRERENDER_READY:
      window.prerenderReady = true;
      return {
        ...state,
        prerenderReady: true,
      };
    default:
      return state;
  }
};
